/* Applications.css */

.applications-container {
  width: 75%; /* Adjust the width as needed */
  max-width: 1200px; /* Adjust the max-width as needed */
  margin-left: auto; /* Center horizontally */
  margin-right: auto; /* Center horizontally */
}

.button-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  
  /* Style for the "Add Application" button */
  .add-application-button {
    font-size: 18px; /* Adjust the font size as needed */
    padding: 12px 24px; /* Adjust the padding as needed */
    border-radius: 8px; /* Add rounded corners */
    border: none; /* Remove the button border */
    text-align: center; /* Center the text horizontally */
    cursor: pointer; /* Add pointer cursor on hover */
    line-height: 0.5; /* Adjust the line-height to center text vertically */
  }
  
  .add-application-button:hover {
    background-color: #40a9ff; /* Change the hover background color as desired */
  }