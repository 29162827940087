
.title-section {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 10px;
    /* Add spacing above and below the title section */
    color: Black;
    /* Text color for the title and blurb */

}

.title-content {
    text-align: center;
}

.title {
    font-size: 36px;
    margin: 16px 0;
}

.blurb {
    font-size: 18px;
    margin: 16px 0;
}