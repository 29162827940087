.mainEmailComposerContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 768px) {
    .mainEmailComposerContainer {
        margin-top: 0px;
    }
    
}