.posting-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0px;
    height: 100%;
    width: 100%;
    min-width: 300px;
    min-height: 250px;
    position: relative;
}

.item-label {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 5px;
    padding-right: 5px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-bottom-right-radius: 8px;
}

.logo-container {
    display: flex;
    align-items: center;
    /* Center vertically */
    justify-content: center;
    /* Center horizontally */
    margin-bottom: 4px;
    /* Adjust the margin as needed */
}

.logo-image {
    width: 100px;
    /* Set the width of the square */
    height: 100px;
    /* Set the height of the square */
    object-fit: contain;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center horizontally */
    text-align: center;
    /* Center text content */
}

.company-name {
    font-weight: bold;
    /* Make the company name bold, adjust as needed */
    margin-bottom: 2px;
    /* Adjust the margin as needed */
}

h4 {
    margin: 0;
    /* Remove any default margins */
}

.location {
    margin-top: 0px;
    /* Adjust the margin as needed */
}

.action-container {
    height: 40px;
    /* Adjust the height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -10px;
    /* Adjust the margin as needed */
}

.divider {
    border-top: 1px solid #ccc;
    /* Add a 1px solid line with gray color */
    width: 90%;
    /* Adjust the width of the line as needed */
    margin: 8px auto;
    /* Center the line horizontally and add vertical spacing */
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    .posting-card {
        padding: 8px;
        min-width: 100%;
        min-height: auto;
    }

    .logo-container {
        margin-bottom: 8px;
    }

    .text-container {
        text-align: left;
        align-items: flex-start;
    }

    .company-name {
        font-size: 16px;
    }

    .contact-info span {
        font-size: 12px;
    }

    .contact-info a {
        margin-right: 5px;
    }

    .divider {
        margin: 16px 0;
    }

    .action-container {
        text-align: center;
    }
}