.header-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.contact-name {
    margin-bottom: 0;
}

.forward-button {
    margin-right: 20px;
}

@media (max-width: 600px) {
    .header-container {
        flex-direction: column;
        align-items: center;
    }

    .forward-button {
        margin-right: 0;
        margin-top: 10px;
    }
}