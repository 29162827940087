/* Custom styling for the location search box */
.locationSearch {
  position: relative;
  display: inline-block;
  width: 100%;
}

/* Style the input container */
.locationSearch .react-autosuggest__container {
  position: relative;
}

/* Style the input field */
.locationSearch .react-autosuggest__input {
  width: 100%;
  padding: 8px 12px;
  /* Adjust padding as needed */
  border: 1px solid #d9d9d9;
  /* Match Ant Design's input border color */
  border-radius: 8px;
  /* Match Ant Design's input border radius */
  font-size: 14px;
  /* Match Ant Design's font size */
  transition: border-color 0.3s, box-shadow 0.3s;
}

/* Style the input field when it's focused */
.locationSearch .react-autosuggest__input:focus {
  outline: none;
  border-color: #40a9ff;
  /* Match Ant Design's input focus border color */
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  /* Match Ant Design's input focus box shadow */
}

/* Style the suggestions container */
.locationSearch .react-autosuggest__suggestions-container {
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 200px;
  /* Adjust the maximum height as needed */
  overflow-y: auto;
  z-index: 999;
  background-color: #fff;
  /* Match Ant Design's input background color */
  border-radius: 2px;
  /* Match Ant Design's input border radius */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  /* Match Ant Design's input suggestions box shadow */
}

/* Style individual suggestion items */
.locationSearch .react-autosuggest__suggestion {
  padding: 8px 12px;
  /* Adjust padding as needed */
  cursor: pointer;
}

/* Style selected suggestion item */
.locationSearch .react-autosuggest__suggestion--highlighted {
  background-color: #e6f7ff;
  /* Match Ant Design's input suggestions highlight color */
}

/* This will target the ul containing the suggestions */
.locationSearch .react-autosuggest__suggestions-list {
  list-style-type: none;
  /* Removes bullet points */
  padding-left: 0;
  /* Removes any default padding */
}

/* This will target individual suggestion items */
.locationSearch .react-autosuggest__suggestion {
  padding: 10px;
  /* Adds some padding for each suggestion for better UX */
  cursor: pointer;
  /* Changes the cursor to pointer when hovering over a suggestion */
}

/* This will target the suggestion when it's highlighted/active */
.locationSearch .react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
  /* Adds a light gray background for the active suggestion */
}