/* Style cards within sections */
.ant-card {
    border: 1px solid #ddd; /* Add a border around cards */
    border-radius: 8px; /* Add rounded corners to cards */
    transition: box-shadow 0.3s ease; /* Add a smooth transition for hover effect */
  
    /* Style card content */
    .ant-card-body {
      padding: 16px; /* Add padding to card content */
    }
  }
  
  /* Hover effect for cards */
  .ant-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow on hover */
    transform: translateY(-2px); /* Lift the card slightly on hover */
  }
  
  /* Style Statistic components */
  .ant-statistic {
    text-align: center; /* Center align the statistics */
    margin-bottom: 16px; /* Add space between statistics */
    color: #555; /* Dark text color */
  }
  
  /* Style buttons within sections */
  .ant-btn {
    margin-right: 8px; /* Add space between buttons */
    font-weight: bold; /* Make button text bold */
  }
  
  /* Add hover effect for buttons */
  .ant-btn:hover {
    background-color: #ddd;
    color: #333;
  }

  .application-statistic-col {
    width: 15%;
  }

  /* Add this to your CSS file or within a <style> tag in your component */
@media (max-width: 768px) {
  .application-statistic-col {
    flex: 0 0 100%;
    margin-right: 10px; /* Adjust as needed for spacing */
    margin-bottom: 7.5px;
  }
  
}
  