/* Example CSS for the modal */
/* AddApplicationModal.css */

.ant-modal-content {
  margin-left: 0%;
}

.custom-modal {
  justify-content: center;
  align-items: center;
}

.center-form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  
}

/* Adjust input and label styles for better alignment */
.ant-form-item-label {
  text-align: center;
  width: 100%;
}

.ant-form-item-control-input {
  text-align: center;
}
