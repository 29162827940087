/* Add this CSS to your stylesheet */

.full-match-card {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 50px;
}

.connectWithText {
    text-align: center;
    font-size: 1.5em;
    color: #333;
    margin: 10px 0;
}

.keyword-scroller {
    display: flex;
    overflow-x: auto;
    padding: 10px 0;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.not-interested {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #d3d3d3;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 2px 5px; /* Reduced padding */
    font-size: 12px; /* Smaller font size */
    cursor: pointer;
}

.keyword-scroller-card {
    padding-bottom: 0px !important;
    
}
/* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
.keyword-scroller::-webkit-scrollbar {
    display: none;
}

.view-more-button {
    display: block;
    margin: 0 auto;
    padding: 0px 5px;
    font-size: 14px;
    background-color: rgb(44, 164, 204);
    color: white;
    cursor: pointer;
    text-align: center;
}

.view-more-button:hover {
    background-color: #40a9ff;
}

@media (max-width: 768px) {
    .full-match-card {
        padding: 16px;
    }

    .keyword-scroller {
        padding: 1px ;
        margin: 0px;
    }

    .not-interested {
        top: 2.5px;
        right: 2.5px;
        padding: 1px 3px;
        font-size: 10px;
        margin: 5px;
        z-index: 1;
    }

    .match-card-row {
        flex-direction: column;
    }

    .match-card-col {
        width: 100%;
        margin-bottom: 16px;
    }

    /* .company-description-card {
        display: none;
    } */

    .connectWithText {
        display: none;
    }

    .matchCardDivider {
        display: none;
    }

    .email-compose {
        margin-top: 16px;
    }
}





/* Job Posting Card Specific */


.button-container {
    display: flex;
    justify-content: center;
    gap: 10px; /* Optional: Add some space between the buttons */
    margin-top: 10px; /* Optional: Add some margin to the top */
}