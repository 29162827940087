.main-inbox {
    margin-left: 30px;
}

.email-list {
    min-height: '100vh'
}


@media (max-width: 768px) {
    .main-inbox {
        margin-left: 0;
    }
}