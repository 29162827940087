.truncate-text {
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}

@media (max-width: 768px) {
    .contact-info .truncate-text {
        max-width: 150px; /* Adjust the width as needed */
    }
}