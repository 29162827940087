.meeting {
    list-style-type: none;
    font-size: 12px;
    margin-left: -30px;
    margin-right: 10px;
    background-color: #1677ff;
    border: 1px solid #1677ff;
    color: white;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 5px;
}