:root {
  --primary: #001529;      /* Dark blue */
  --secondary: #0B5FFF;    /* Bright blue */
  --tertiary: #000000;     /* Black */
  --light: #fafafa;        /* Light gray */
  --dark: #272f32;         /* Dark gray */
  --error: rgb(228, 46, 1);/* Red (error color) */
  --spacing-one: calc(1rem * 0.5);
  --spacing-two: calc(1rem);
}

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Karla', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  color: var(--dark);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  margin: 5px 0;
  line-height: 1.25;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1.1rem;
  margin-top: 0;
}

a {
  color: var(--tertiary);
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

header {
  background: var(--primary);
}

header a {
  color: var(--light);
}

button {
  font-size: 1rem;
  text-decoration: none;
  padding: 0.25rem 1rem;
  border-radius: .5rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 1px;
  background-color: var(--secondary);
}

button:hover {
  opacity: .8;
}

button[disabled] {
  cursor: default;
  opacity: .8;
}

img {
  max-width: 100%;
}

span[role="img"] {
  cursor: default;
}

form {
  width: 100%;
}

input {
  font-size: 1rem;
  padding: 0.25rem;
  border: 1px solid var(--dark);
  outline: none;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

input:focus {
  border: 1px solid var(--secondary);
  box-shadow: 0 0 .25rem rgba(0, 0, 0, .5);
}

.container {
  width: 85%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

.my-1 {
  margin-top: var(--spacing-one);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: var(--spacing-two);
  margin-bottom: var(--spacing-two);
}

.px-1 {
  padding-right: var(--spacing-one);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-two);
}

.py-1 {
  padding-top: var(--spacing-one);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: var(--spacing-two);
  padding-bottom: var(--spacing-two);
}

.card {
  width: 25%;
  text-align: center;
}

.error-text {
  color: var(--error);
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%;
  }
}

.button-color {
  background-color: #2ca4cc !important;
  color: white !important;
}

.button-color:hover {
  background-color: #5ce4f4 !important;
  border-color: #5ce4f4  !important; /* Change as needed */
  color: white !important; /* Change as needed */
}

.button-color-premium {
  background-color: #fcdc5c !important;
  color: black !important;
}

.button-color-premium:hover {
  background-color: #f7e9a9 !important;
  border-color: #f7e9a9  !important; /* Change as needed */
  color: #aa8800 !important; /* Change as needed */
}

.button-color-premium:disabled,
.button-color-premium.disabled {
  background-color: #e6c854 !important; /* Disabled background color */
  color: #a0a0a0 !important; /* Disabled text color */
  border-color: #d0d0d0 !important; /* Disabled border color */
  cursor: not-allowed !important; /* Change cursor to not-allowed */
}

/* For Float Button */
.float-button-color  {
  background-color: #2ca4cc !important;
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  font-size: 20px; /* Adjust as needed */
}

.float-button-color div {
  background-color: #2ca4cc !important;
  color: white !important;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

.button-pulse {
  animation: pulse 5s infinite;
}

@keyframes todo-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}


.todo-pulse {
  animation: todo-pulse 4s infinite;
  color:  #2ca4cc !important
}

@keyframes match-pulse {
  0% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(1.40);
  }
  100% {
    transform: scale(1.25);
  }
}

.view-matches-button {
  animation: match-pulse 5s infinite;
}

.apply-button {
  background-color: #2ca4cc !important;
  color: white !important;
  animation: pulse 5s infinite;
}


.fade-enter {
  position: absolute;
  width: 100%; /* Set explicit width */
  height: auto; /* Set explicit height */
  transform: translateX(100%);
  z-index: 2;
}

.fade-enter-active {
  transform: translateX(0);
  transition: transform 1500ms;
}

.fade-exit {
  position: absolute;
  width: 100%; /* Set explicit width */
  height: auto; /* Set explicit height */
  opacity: 1;
  z-index: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1500ms; /* Increased opacity transition duration */
}

.no-scroll-background-main {
  overflow: hidden;
}

.refer-to-contact {
  color: #2ca4cc !important;
  text-decoration: underline var(--dark);
}





