.full-height-row {
    min-height: 70vh;
  }
  
  .full-height-col {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
  }
  
  .full-height-col .ant-table-wrapper {
    flex: 1;
  }
  
  .full-height-col .resume-previewer {
    flex: 1;
    overflow: auto;
  }