.target-job-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    flex: 1;
}

.target-job-content {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.job-details {
    padding: 20px;
    flex: 3;
}

.job-actions {
    padding: 20px;
    flex: 2;
    max-width: 40vw;
}

.vertical-divider {
    height: auto;
}

.parentJobFieldInput {
    width: 350px;
}

.jobFieldInput {
    width: 350px;
}

.jobTags {
    padding: 5px 15px;
    font-size: 14px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.more-icon {
    display: inline-block;
    text-align: center;
    font-size: 12px;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.more-icon:hover {
    background-color: #f0f0f0;
    color: #0d7bc1;
}

.more-icon-text {
    font-weight: bold;
}

@media screen and (max-width: 768px) {

    .target-job-container {
        max-height: 75vh;
        /* Set the maximum height */
        overflow-y: auto;
        /* Make it scrollable when content exceeds the max height */
    }

    .target-job-content {
        flex-direction: column;
        margin-top: 5px;
    }

    .job-details {
        width: 100%;
        padding: 0;
    }

    .job-actions {
        display: flex;
        justify-content: center;
        max-width: 75vw;
        padding: 0;
    }

    .vertical-divider {
        display: none;
    }

    .parentJobFieldInput,
    .jobFieldInput {
        max-width: 75vw;
    }

    .job-details {
        margin-bottom: 20px;
    }

    .jobTags {
        padding: 2.5px 5px;
        font-size: 12px;
    }
}