/* Add this CSS to your stylesheet */

.keyword-scroller {
    display: flex;
    overflow-x: auto;
    padding: 10px 0;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}


.keyword-scroller-card {
    padding-bottom: 0px !important;
    
}
/* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
.keyword-scroller::-webkit-scrollbar {
    display: none;
}


@media (max-width: 768px) {

    .keyword-scroller {
        padding: 1px ;
        margin: 0px;
    }

}
