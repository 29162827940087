
.applist-title-section {
  margin-top: 2.5%;
}


/* Add this CSS to style the filter and sort buttons */
.filter-sort-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 8px;
    gap: 8px; /* Adjust the gap between buttons as needed */
  }
  
  /* Style the filter and sort buttons */
  .filter-sort-section span {
    font-weight: bold;
    margin-right: 0px; /* Add space to the right of the 'Filter' and 'Sort' text */
  }
  
  .filter-sort-section Button {
    background-color: #f0f0f0; /* Button background color */
    color: #333; /* Button text color */
    border: none; /* Remove button border */
    border-radius: 4px; /* Add rounded corners to buttons */
    padding: 4px 12px; /* Adjust padding as needed */
    cursor: pointer; /* Add pointer cursor on hover */
  }
  
  /* Hover effect for buttons */
  .filter-sort-section Button:hover {
    background-color: #ddd; /* Change background color on hover */
    color: #555; /* Change text color on hover */
  }

  .filter-sort-section .active {
    color: #4096ff; /* Change text color on hover */
  }




  .filter-sort-drawer-section {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 8px;
    gap: 8px; /* Adjust the gap between buttons as needed */
  }

  /* Hover effect for buttons */
  .filter-sort-drawer-section Button:hover {
    background-color: #ddd; /* Change background color on hover */
    color: #555; /* Change text color on hover */
  }

  .filter-sort-drawer-section .active {
    color: #4096ff; /* Change text color on hover */
  }