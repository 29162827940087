.upload-resume-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    flex: 1;
}

.mission-statement {
    margin-top: 20px;
    font-size: 24px;
}

.get-started {
    margin-top: 20px;
}

.upload-resume-content {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.dragger-container {
    width: 50%;
    padding-right: 15px;
    border-right: 1px solid gainsboro;
    text-align: center;
}

.manual-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 50%;
    padding-left: 15px;
    text-align: center;
}

.modal-center {
    position: fixed;
    top: 50%;
    left: 50%;
}

@media screen and (max-width: 768px) {
    .upload-resume-content {
        flex-direction: column;
    }

    .mission-statement {
        font-size: 14px;
    }

    .dragger-container, .manual-button-container {
        width: 100%;
        padding: 0;
        border: none;
    }

    .dragger-container {
        margin-bottom: 20px;
    }

    .dragger-text {
        font-size: 16px;
    }

    .dragger-instruction {
        display: none;
    }
}