.opportunity-card {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 16px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .opportunity-card:hover {
    background-color: #f0f0f0;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .image-container {
    margin-right: 10px;
  }
  
  .opportunity-image {
    width: 50px; /* Adjust the size as needed */
    height: 50px; /* Adjust the size as needed */
    object-fit: cover;
    border-radius: 8px; /* Optional: Add border radius for rounded corners */
  }

  .opportunity-title {
    margin-top: 0px; /* Add space above the text */
    margin-bottom: 20px;
    font-size: 1.2em; /* Make the text larger */
    font-weight: bold; /* Make the text bold */
  }

  .page-fold {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px; /* Adjust the size as needed */
    height: 30px; /* Adjust the size as needed */
    background-color: #f0f0f0; /* Adjust the color as needed */
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    transition: background-color 0.3s;
  }
  
  .opportunity-card:hover .page-fold {
    background-color: #e0e0e0; /* Adjust the hover color as needed */
  }