.main-container {
    width: 90%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.title-section {
    display: flex;
    justify-content: space-between;
}

.title {
    margin: 0;
}

.count {
    margin: 0
}

.discover-text {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px
}

@media (max-width: 768px) {
    .main-container {
        width: 95%;
    }

    .title-section {
        margin: 5px;
        padding: 0;
    }

    .title {
        font-size: 0.8rem;
    }

    .count {
        font-size: 0.8rem;
    }

    .discover-text {
        margin: 10px;
        margin-bottom: 30px;
    }
}