/* Contacts.css */

.main-contacts-container {
  width: 85%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}


.button-container {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

/* Style for the "Add Application" button */
.add-contact-or-company-button {

  background-color: #1890ff;
  /* Change the background color as desired */

}

.add-contact-button:hover {
  background-color: #40a9ff;
  /* Change the hover background color as desired */
}

.contacts-toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.contact-title {
  font-size: 24px;
  font-weight: bold;
  margin: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  text-align: center;
}


@media screen and (max-width: 768px) {

  .main-contacts-container {
    width: 97.5%;
  }

  .contact-title {
    font-size: 16px;
    margin: 5px;
  }

  .contacts-toggle-container {
    margin: 5px;
  }

  .add-contact-or-company-button {
    font-size: 14px;
    margin-bottom: 5px !important;
  }

}