.todo-card {
    background-color: #fcdc5c !important;
    color: black !important;
    border: 1px solid #fcce17 !important;
}

.todo-card .ant-card-actions {
    background-color: #fcdc5c !important;
    border-top: 1px solid #fcce17 !important;
}

.todo-card .ant-card-head {
    background-color: #fcdc5c !important;
    border-bottom: 1px solid #fcce17 !important;
}