.site-layout-content {
    max-width: 600px;
    margin: 0 auto;   
}

.signup-form {
    width: 100%;
}

.signup-input {
    width: 100%;
}

.signup-button {
    width: 100%;
}