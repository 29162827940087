/* Add this CSS to your stylesheets */

.Hiremize-Title {
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 20px;
  font-size: 28px;
  color: #fff;
}

.nav-items {
  margin-left: auto;
}

.nav-items a {
  margin-right: 5px;
}

img {
  height: 60px;
  width: auto;
  margin-right: 10px;
}

li {
  list-style-type: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

.button-pulse {
  animation: pulse 5s infinite;
}