.sider {
    color: #fff;
    margin-right: 10px;
    position: fixed;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }


.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    margin-left: 610px;
    background: #fff;
    color: #020b16;
  }

  
  @media (max-width: 768px) {

    .sider {
        width: 100%;
        position: relative;
        margin-right: 0;
        flex: 0;
      }
    .content {
      display: none;
    }

  }