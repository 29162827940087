.custom-modal .ant-modal-content {
  border-radius: 8px;
}

.custom-modal .ant-modal-header {
  text-align: center; /* Center align the header title */
  border-bottom: none;
}

.custom-modal .ant-modal-body {
  display: flex; /* Use flexbox to center contents */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
}

.custom-modal .ant-modal-footer {
  border-top: none;
  display: flex;
  justify-content: center; /* Center footer buttons */
}

.custom-form .form-item {
  margin-bottom: 16px;
  width: 100%; /* Full width to align with other inputs */
}

.custom-form .autocomplete-wrapper {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the autocomplete and button */
}

.custom-form .check-icon {
  margin-left: 10px;
  color: green;
}

.custom-form .quick-add-button {
  margin-left: 10px;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .custom-modal .ant-modal-body {
    padding: 24px;
  }
}
