/* Add these styles to your CSS */

/* Style for the container holding the search elements */
.search-box {
    display: flex; /* Use flexbox to align items horizontally */
    gap: 2px; /* Add some spacing between the elements */
    align-items: center; /* Vertically center items within the container */
    margin-bottom: 28px; /* Add some spacing below the container */
  }
  
  /* Style for the input fields */
  .input-field {
    flex: 1; /* Allow input fields to grow and take available space */
  }
  
  /* Style for the button */
  .search-button {
    min-width: 80px; /* Adjust the width as needed */
  }
  