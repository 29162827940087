/* ApplicationItem.css */
.app-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    width: 100%; /* Ensure cards take up the full width */
    position: relative;
    box-sizing: border-box; /* Include padding and border in the total width */
  }

  .favorite-icon {
    font-size: 24px;
    margin-right: 5px;
  }
  
  .status-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 33%; /* Adjust the minimum width as needed */
    padding-right: 10%; /* Add space between sections */
  }

  .status-text {
    font-size: 28px; /* Adjust the font size as needed */
    margin-right: 18px;
  }

  .status-buttons {
    display: flex;
    align-items: center; /* Center vertically */
  }
  
  .company-logo-section {
    min-width: 100px; /* Adjust the minimum width as needed */
    margin-right: 16px; /* Add space between sections */
  }

  .company-logo {
    width: 75px; /* Adjust the width as needed */
    height: 75px; /* Adjust the height as needed */
  }
  
  .company-details-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1; /* Allow this section to grow and fill available space */
    /* margin-right: 20px; Add space between sections */
  }

  .position {
    margin-bottom: 4px; /* Adjust the margin as needed */
    font-weight: normal;
  }

  .location {
    margin-bottom: 4px; /* Adjust the margin as needed */
    font-weight: normal;
  }
  
  .actions-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 50px;
  }

  .actions-buttons {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .action-button-row {
    display: flex;
    align-items: center;
  }

  .action-row-number {
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 50px;
    padding-left: 7px;
    padding-right: 7px;
  }

 
  
  /* Add more specific styling for buttons and icons as needed */
  