

.mainWelcomeStep {
    margin: 12.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.logo-welcome {
    height: 200px;
    width: auto;
    margin-bottom: 20px;
}

.welcome {
    margin-bottom: 0px;
}

@media screen and (max-width: 768px) {
    .mainWelcomeStep {
        margin: 0 !important;
    }
    .logo-welcome {
        height: 100px;
        width: auto;
        margin-bottom: 20px;
    }
    .welcome {
        font-size: 2.0rem;
        margin-bottom: 15px;
    }
    
}