/* Add a background color to sections */
.dashboard-section,
.recommended-jobs-section,
.follow-up-jobs-section {
  /* margin-bottom: 30px;
  margin-right: 40px;
  margin-left: 40px; */
  background-color: #fff;
  /* Light gray background */
  padding: 20px;
  /* Add padding for better spacing inside sections */
  border-radius: 8px;
  /* Add rounded corners to sections */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Add a subtle shadow */
  border-color: #ddd;
}

/* Style headings */
h1 {
  font-size: 24px;
  /* Increase font size for headings */
  color: #333;
  /* Dark text color */
}

h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  /* Increase font size for headings */
  color: #333;
  /* Dark text color */
}

/* Add spacing between columns and rows in the recommended job and follow-up job sections */
.recommended-jobs-section .ant-row,
.follow-up-jobs-section .ant-row {
  margin-right: -8px;
  margin-left: -8px;
}

.recommended-jobs-section .ant-col,
.follow-up-jobs-section .ant-col {
  padding: 0 8px;
}

/* Add space between items in the recommended job and follow-up job sections */
.recommended-jobs-section .ant-col,
.follow-up-jobs-section .ant-col {
  margin-bottom: 16px;
  /* Adjust as needed */
}

/* .flex-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
} */

.homepage {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;  /* changed from center */
  justify-content: space-between;

}
.application-stages {
  order: 1
}

.mainStats {
  order: 3;
  width: 27.5%;
  height: 100%;
  margin-bottom: 10px;
}

.toDos {
  order: 2;
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


/* Add this to your CSS file or within a <style> tag in your component */
@media (max-width: 768px) {
  .homepage {
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
  }

  .application-stages {
    order: 3 !important; /* Keep application-stages at the bottom on mobile */
  }

  .mainStats {
    order: 2 !important; /* Keep mainStats in the middle on mobile */
    margin-top: 10px;
  }

  .toDos {
    order: 1 !important;/* Move toDos to the top on mobile */
  }

  /* .dashboard-section .ant-row {
    
  } */

  .dashboard-section .ant-row > div {
    width: 100%;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .flex-container > div {
    width: 100%;
  }

  .dashboard-section {
    width: 100%;
    display: flex;
    overflow-y: auto;
    white-space: nowrap;
  }
}